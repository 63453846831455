import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';

const HomePage = () => {
    const navigate = useNavigate();
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        setFadeIn(true);
    }, []);

    return (
        <div className={`homepage-container ${fadeIn ? 'fade-in' : ''}`}>
            <img
                src={process.env.PUBLIC_URL + '/home/homebackground.png'}
                alt="Home Background"
                className="home-background"
            />

            {/* New Image - homepageui.png from /public/icons folder */}
            <img
                src={process.env.PUBLIC_URL + '/icons/homepageui.png'}
                alt="Home UI"
                className="home-ui"
            />

            <button className="home-settings-button" onClick={() => navigate('/settings')}>
                <img
                    src={process.env.PUBLIC_URL + '/home/homesettings.png'}
                    alt="Home Settings"
                    className="home-settings"
                />
            </button>
            <button className="home-history-button" onClick={() => navigate('/dashboard')}>
                <img
                    src={process.env.PUBLIC_URL + '/home/homehistory.png'}
                    alt="Home History"
                    className="home-history"
                />
            </button>
            <button className="home-book-button" onClick={() => navigate('/form')}>
                <img
                    src={process.env.PUBLIC_URL + '/home/homebook.png'}
                    alt="Home Book"
                    className="home-book"
                />
            </button>
        </div>
    );
};

export default HomePage;






























