import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./Storyboard.css";
import { API_ENDPOINT } from "./var.js";

const Storyboard = () => {
  const { storyId } = useParams();
  const navigate = useNavigate();
  const [story, setStory] = useState(null);
  const [currentSceneIndex, setCurrentSceneIndex] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [highlightedBlockIndex, setHighlightedBlockIndex] = useState(-1); // Track which punctuation block is highlighted
  const [prefetchedImages, setPrefetchedImages] = useState({});
  let blockIndex = 0;

  // Fetch story data
  useEffect(() => {
    const fetchStory = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}/story/${storyId}`);
        if (response.ok) {
          const data = await response.json();
          setStory(data);
        } else {
          console.error("Failed to fetch story data");
        }
      } catch (error) {
        console.error("Error fetching story data:", error);
      }
    };
    fetchStory();
  }, [storyId]);

  // Function to generate an image for a given scene index
  const generateImage = useCallback(
    async (sceneIndex) => {
      const scene = story?.scenes?.[sceneIndex];
      if (!scene) {
        console.error("Invalid scene data");
        return;
      }

      try {
        const response = await fetch(`${API_ENDPOINT}/generate-image`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storyId,
            sceneIndex,
            imagePrompt: scene.imagePrompt,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          setStory((prevStory) => ({
            ...prevStory,
            scenes: prevStory.scenes.map((scene, index) =>
              index === sceneIndex ? { ...scene, imageUrl: data.imageUrl } : scene
            ),
          }));
          return data.imageUrl;
        } else {
          console.error("Failed to generate image");
        }
      } catch (error) {
        console.error("Error generating image:", error);
      }

      return null;
    },
    [story, storyId]
  );

  // Prefetch images for the next scene
  const prefetchNextImage = useCallback(
    async (index) => {
      if (
        story &&
        story.scenes &&
        story.scenes[index] &&
        !story.scenes[index].imageUrl &&
        !prefetchedImages[index]
      ) {
        const imageUrl = await generateImage(index);
        if (imageUrl) {
          setPrefetchedImages((prev) => ({ ...prev, [index]: imageUrl }));
        }
      }
    },
    [story, generateImage, prefetchedImages]
  );

  // Handle image generation and prefetching
  useEffect(() => {
    if (story && story.scenes) {
      // Prefetch current image if not available
      if (!story.scenes[currentSceneIndex].imageUrl) {
        generateImage(currentSceneIndex);
      }
      // Prefetch next image
      prefetchNextImage(currentSceneIndex + 1);
    }
  }, [story, currentSceneIndex, generateImage, prefetchNextImage]);

  // Handle text-to-speech and punctuation-based highlighting
  const handleTextToSpeech = () => {
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
      setHighlightedBlockIndex(-1); // Reset when speech is stopped
    } else {
      const currentText = story.scenes[currentSceneIndex]?.text || "No scene text available";

      // Split the text by punctuation marks and keep them together
      const punctuationBlocks = currentText.split(/([.,!?;])/g).reduce((acc, curr, index, arr) => {
        if (index % 2 === 0) {
          acc.push(curr + (arr[index + 1] || ""));
        }
        return acc;
      }, []);

      const speech = new SpeechSynthesisUtterance(currentText);
      blockIndex = 0; // Reset block index

      speech.onboundary = (event) => {
        // Move to the next block when we encounter punctuation
        const charIndex = event.charIndex;

        // Check if the current charIndex corresponds to the next block start
        let nextBlockStartIndex = 0;
        for (let i = 0; i < blockIndex; i++) {
          nextBlockStartIndex += punctuationBlocks[i].length;
        }

        // Highlight the next block if speech reached the next block
        if (charIndex >= nextBlockStartIndex && blockIndex < punctuationBlocks.length) {
          setHighlightedBlockIndex(blockIndex);
          blockIndex++;
        }
      };

      speech.onend = () => setIsSpeaking(false);
      window.speechSynthesis.speak(speech);
      setIsSpeaking(true);
    }
  };

  // Handle scene navigation
  const handlePrevious = () => {
    setCurrentSceneIndex((prev) => Math.max(0, prev - 1));
    setHighlightedBlockIndex(-1); // Reset highlighted block when navigating
  };

  const handleNext = () => {
    setCurrentSceneIndex((prev) => {
      const next = Math.min(story.scenes.length - 1, prev + 1);
      prefetchNextImage(next + 1);
      return next;
    });
    setHighlightedBlockIndex(-1); // Reset highlighted block when navigating
  };

  // Navigate back to story list
  const handleClose = () => {
    navigate("/story");
  };

  if (!story) return <div>Loading...</div>;

  const currentScene = story.scenes[currentSceneIndex];

  // Split the text into punctuation-based blocks for rendering
  const punctuationBlocks = currentScene.text
    .split(/([.,!?;])/g)
    .reduce((acc, curr, index, arr) => {
      if (index % 2 === 0) {
        acc.push(curr + (arr[index + 1] || ""));
      }
      return acc;
    }, []);

  return (
    <div className="storyboard-container">
      {/* Scene background image */}
      <div
        className="scene-image"
        style={{ backgroundImage: `url(${currentScene.imageUrl})` }}
      >
        {/* Header with back and bookmark buttons */}
        <div className="story-header">
          <button className="back-button" onClick={handleClose}>
            <img src={process.env.PUBLIC_URL + "/icons/back-arrow.png"} alt="Back" />
          </button>
          <h2 className="chapter-title">Chapter {currentSceneIndex + 1}</h2>
          <button className="bookmark-button">
            <img
              src={process.env.PUBLIC_URL + "/icons/bookmark.png"}
              alt="Bookmark"
            />
          </button>
        </div>

        {/* Story Text Box */}
        <div className="story-text-box">
          <h3>A Journey of Friendship</h3>
          <p className="story-text">
            {punctuationBlocks.map((block, index) => (
              <span
                key={index}
                className={index === highlightedBlockIndex ? "highlighted" : ""}
              >
                {block}{" "}
              </span>
            ))}
          </p>
        </div>
      </div>

      {/* Playback controls */}
      <div className="controls">
        <button
          className="previous-button"
          onClick={handlePrevious}
          disabled={currentSceneIndex === 0}
        >
          <img
            src={process.env.PUBLIC_URL + "/icons/previous.png"}
            alt="Previous"
          />
        </button>
        <button className="play-button" onClick={handleTextToSpeech}>
          <img
            src={
              isSpeaking
                ? process.env.PUBLIC_URL + "/icons/pause.png"
                : process.env.PUBLIC_URL + "/icons/play.png"
            }
            alt="Play/Pause"
          />
        </button>
        <button
          className="next-button"
          onClick={handleNext}
          disabled={currentSceneIndex === story.scenes.length - 1}
        >
          <img src={process.env.PUBLIC_URL + "/icons/next.png"} alt="Next" />
        </button>
        <button className="settings-button">
          <img
            src={process.env.PUBLIC_URL + "/icons/settings.png"}
            alt="Settings"
          />
        </button>
      </div>
    </div>
  );
};

export default Storyboard;














